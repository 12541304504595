import {useState} from "react";
import {isEmpty, isNumber} from "lodash";
import dayjs from "dayjs";

export const numberWithCommas = (x) => {
    return numberTriple(x, ".");
};
export const numberWithDot = (x) => {
    return numberTriple(x, ".");
};
export const countDownTimer = (x) => {
    let [timerCount, setTimerCount] = useState("");
    let countDownDate = (new Date(x)).getTime();
    let timeCount = setInterval(function () {
        // getCurrentDate
        let now = new Date().getTime();

        // Find the distance between now and the count down date
        let distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance < 0) {
            setTimerCount("Đã hết hạn");
            return clearInterval(timeCount);
        } else {
            if (days > 0) setTimerCount(`Còn ${days} ngày`);
            if (days == 0) setTimerCount(`Còn ${hours}:${minutes}:${seconds}`);
            if (days < 0) setTimerCount(`Đã hết hạn`);
        }
    }, 1000);
    return timerCount;
};

export const countDownTimerTemp = (x, textAfter = '') => {
    const day = dayjs(x).diff(dayjs(), 'day');
    const hour = dayjs(x).diff(dayjs(), 'hour');
    const minute = dayjs(x).diff(dayjs(), 'minute');
    if (day > 0) return `Còn ${day} ngày ${textAfter}`;
    if (hour > 0) return `Còn ${hour} giờ ${textAfter}`;
    if (minute > 0) return `Còn ${minute} phút ${textAfter}`;
    if (minute == 0) return `Hết hạn trong 1 phút`;
};

function numberTriple(x, split) {
    x = Math.round(x);
    const p = x.toString().split(".");
    const result = p[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, split);
    return p[1] ? `${result}.${p[1]}` : result;
};
export const displayWithK = (x) => {
    if (x / 1000 >= 1) {
        return numberWithDot(Math.round(x / 1000 * 10) / 10) + "K";
    } else {
        return (x);
    }
};
export const displayFromTime = (x) => {
    let mapDisplay = ["năm", "tháng", "ngày", "giờ", "phút", "giây"];
    let xDate = x.split(" ")[0]?.split("-").concat(x.split(" ")[1]?.split(":")).map(x => parseInt(x));
    const a = new Date();
    const now = [a.getFullYear(), a.getMonth() + 1, +a.getDate(), +a.getHours(), +a.getMinutes(), +a.getSeconds()];
    let res = "";
    now.forEach((k, i) => {
        if (!isEmpty(res)) {
            return;
        }
        ;
        const time = k - xDate[i];
        if (time > 0) {
            res = time + " " + mapDisplay[i];
            return;
        }
        ;
    });
    return res;
};

function escapeRegExp(string) {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
export const replaceAll = (str = '', find = '', replace = '') => {
    if (!str || !find) return '';
    return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
};

export const formatSku = (sku) => {
    return encodeURIComponent(sku);
};

export const formatString = (input) => {
    return input.replace(/[^0-9a-z]/gi, '');
};

export const upperCaseFirst = (x) => {
    return x.charAt(0).toUpperCase() + x.slice(1);
};

export const getTypeArticle = (item) => {
    if (item.post_type == 'video' || item.first_term?.id == 303) return 'happynesttv';

    if (item.first_term?.id == 297) return 'review';

    if (item.post_type_id == 3 || item.post_type_id == 6) {
        return 'album';
    }
    if (item.post_type_id == 4) {
        return 'thao-luan';
    }
    return 'chuyen-nha';
};

export const changeMonthFirst = (time) => {
    const onlyDate = time.split(' ')[0];
    const onlyDateArray = onlyDate.split('/');
    return onlyDateArray[1] + '/' + onlyDateArray[0] + '/' + onlyDateArray[2];
};

function removeAccentsFunc(str) {
    return (str || '').normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/đ/g, 'd').replace(/Đ/g, 'D');
}

export const getUrlName = (str) => {
    const removeAccents = removeAccentsFunc(str);
    const lowerCase = removeAccents.toLowerCase();
    const changeSpace = replaceAll(lowerCase, ' ', '-');
    return encodeURI(changeSpace);
};
export const isMobile = () => {
    let mobile = false; //initiate as false
    // device detection
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
      || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) {
        mobile = true;
    }
    return mobile;
};

export const getXXXPrice = (price) => {
    if (isNumber(price)) {
        price = Math.round(price);
    }
    const x = 'x';
    const xxx = x + x + x;
    if ((price + '').indexOf('.') < 0) {
        price = numberWithCommas(price);
    }
    const priceArray = (price + '').split('.');
    price = priceArray[0][0] + (priceArray[0][1] ? x : '') + (priceArray[0][2] ? x : '');
    for (let i = 1; i < priceArray.length-1; i++) {
        price = price + '.' + xxx;
    }

    price = price + '.' + priceArray[priceArray.length-1];

    return price;
};

export const getSlug = (item) => {
    
};

export const getLinkProfession = (item) => {
    return "/thanh-vien/u/" + item?.id + "?t=tong-quan";
}
export const changeDayOfWeekFromEnToVn = (dayOfWeek) => {
    switch (dayOfWeek) {
        case "Monday":
            return "Thứ hai";
        case "Tuesday":
            return "Thứ ba";
        case "Wednesday":
            return "Thứ tư";
        case "Thursday":
            return "Thứ năm";
        case "Friday":
            return "Thứ sáu";
        case "Saturday":
            return "Thứ bảy";
        case "Sunday":
            return "Chủ nhật";
        default:
            return "";
    }
};