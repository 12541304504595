export let API_URL = process.env.NEXT_ENV === 'production' ? 'https://sellercenter.happynest.vn' : 'https://staging-sellercenter.happynest.vn';
export let API_URL_V2 = process.env.NEXT_ENV === 'production' ? 'https://sapi.happynest.vn/api/v2/' : 'https://api.happynest.vn/api/v2/';
export const API_IMG = process.env.NEXT_ENV === 'production' ? (API_URL + '/') : 'https://static-ecom.happynest.com.vn/';
// export const API_URL = 'https://staging-sellercenter.happynest.vn';
// export const API_URL_V2 = 'https://api.happynest.vn/api/v2/';
// export const API_IMG = 'https://static-ecom.happynest.com.vn/';
// export const API_URL = 'https://sellercenter.happynest.vn';
// export const API_URL_V2 = 'https://sapi.happynest.vn/api/v2/';
// export const API_IMG = API_URL + '/';
export const CHAT = 'https://chat.happynest.vn/v1/';

export const IMG_URL = API_IMG + '/media/catalog/product/';
export const SHIT_IMG_URL = API_IMG;
export const MEMBERSHIP_IMAGE = ["/images/icon-membership02.svg", "/images/icon-membership01.svg", "/images/icon-membership.svg","/images/icon-membership-05.svg","/images/icon-membership-04.svg","/images/icon-membership-03.svg"];
export const POST_TYPE_MAPPING = {
    2: 'chuyen-nha/',
    3: 'album/',
    4: 'thao-luan/',
    6: 'album/'
};
export const EMAIL_FORGOT = 'email_forgot_password';
export const EMAIL_REGISTER = 'email_register';
export const OTP_FORGOT = 'otp_forgot_password';
export const LOCAL_API = API_URL === 'https://sellercenter.happynest.vn' ? 'https://happynest.vn' : 'https://happynest-drab.vercel.app';
export const SLUG_LIST = [
    {
        "id": 561,
        "is_root": true,
        "name": "Chuyện nhà",
        "slug": "chuyen-nha",
        "parent_id": 0,
        "description": "",
        "thumbnail": "https://happynest.vn/storage/uploads/CategoryImage/chuyen-nha.jpg",
        "taxonomy_id": 21,
        "parent": null,
        "permalink": "https://happynest.vn/chuyen-nha/c/561/chuyen-nha"
    },
    {
        "id": 562,
        "is_root": true,
        "name": "Kho ảnh",
        "slug": "kho-anh",
        "parent_id": 0,
        "description": "",
        "thumbnail": "https://happynest.vn/storage/uploads/CategoryImage/kho-anh.jpg",
        "taxonomy_id": 21,
        "parent": null,
        "permalink": "https://happynest.vn/kho-anh/c/562/kho-anh"
    },
    {
        "id": 562,
        "is_root": true,
        "name": "Kho ảnh",
        "slug": "album",
        "parent_id": 0,
        "description": "",
        "thumbnail": "https://happynest.vn/storage/uploads/CategoryImage/kho-anh.jpg",
        "taxonomy_id": 21,
        "parent": null,
        "permalink": "https://happynest.vn/kho-anh/c/562/kho-anh"
    },
    {
        "id": 540,
        "is_root": true,
        "name": "Kho kiến thức",
        "slug": "kho-kien-thuc",
        "parent_id": 0,
        "description": "",
        "thumbnail": "https://happynest.vn/storage/uploads/CategoryImage/san-do-cu.jpg",
        "taxonomy_id": 21,
        "parent": null,
        "permalink": "https://happynest.vn/chuyen-nha/c/540/kho-kien-thuc"
    },
    {
        "id": 781,
        "is_root": true,
        "name": "Happynest Story",
        "slug": "happynest-story",
        "parent_id": 0,
        "description": "",
        "thumbnail": "https://happynest.vn/home2017-assets/images/avatar-happynest@2x.jpg",
        "taxonomy_id": 21,
        "parent": null,
        "permalink": "https://happynest.vn/chuyen-nha/c/781/emagazine"
    },
    {
        "id": 297,
        "is_root": true,
        "name": "Review",
        "slug": "review",
        "parent_id": 0,
        "description": "Review",
        "thumbnail": "https://happynest.vn/storage/uploads/CategoryImage/Review.png",
        "taxonomy_id": 21,
        "parent": null,
        "permalink": "https://happynest.vn/chuyen-nha/c/297/review"
    },
    {
        "id": 303,
        "is_root": true,
        "name": "HappynestTV",
        "slug": "happynesttv",
        "parent_id": 0,
        "description": "HappynestTV",
        "thumbnail": "https://happynest.vn/storage/uploads/CategoryImage/HappynestTV.jpg",
        "taxonomy_id": 21,
        "parent": null,
        "permalink": "https://happynest.vn/chuyen-nha/c/303/happynesttv"
    },
    {
        "id": 294,
        "is_root": true,
        "name": "Xu hướng",
        "slug": "xu-huong",
        "parent_id": 0,
        "description": "Xu hướng",
        "thumbnail": "https://happynest.vn/storage/uploads/CategoryImage/xu-huong.jpg",
        "taxonomy_id": 21,
        "parent": null,
        "permalink": "https://happynest.vn/chuyen-nha/c/294/xu-huong"
    },
    {
        "id": 570,
        "is_root": true,
        "name": "Sự kiện",
        "slug": "su-kien",
        "parent_id": 0,
        "description": "",
        "thumbnail": "https://happynest.vn/storage/uploads/CategoryImage/nha-cho-nguoi-cao-tuoi.jpg",
        "taxonomy_id": 21,
        "parent": null,
        "permalink": "https://happynest.vn/chuyen-nha/c/570/su-kien"
    },
    {
        "id": 572,
        "is_root": true,
        "name": "Thảo luận",
        "slug": "thao-luan",
        "parent_id": 0,
        "description": "",
        "thumbnail": "https://happynest.vn/storage/uploads/CategoryImage/thao-luan.jpg",
        "taxonomy_id": 21,
        "parent": null,
        "permalink": "https://happynest.vn/thao-luan/c/572/thao-luan"
    },
    {
        "id": 786,
        "is_root": true,
        "name": "Kho 360",
        "slug": "kho-360",
        "parent_id": 0,
        "description": "Kho 360",
        "thumbnail": "https://sapi.happynest.vn/img/avatar-happynest@2x.jpg",
        "taxonomy_id": 21,
        "parent": null,
        "permalink": "https://happynest.vn/chuyen-nha/c/786/kho-360"
    },
    {
        "id": 301,
        "is_root": true,
        "name": "Ăn - Chơi",
        "slug": "an-choi",
        "parent_id": 0,
        "description": "Ăn - Chơi",
        "thumbnail": "https://happynest.vn/storage/uploads/CategoryImage/choi-gi-o-dau.jpg",
        "taxonomy_id": 21,
        "parent": null,
        "permalink": "https://happynest.vn/chuyen-nha/c/301/an-choi"
    },
    {
        "id": 563,
        "is_root": true,
        "name": "Chợ đồ cũ",
        "slug": "cho-do-cu",
        "parent_id": 0,
        "description": "",
        "thumbnail": "https://happynest.vn/storage/uploads/CategoryImage/cho-do-cu.jpg",
        "taxonomy_id": 21,
        "parent": null,
        "permalink": "https://happynest.vn/chuyen-nha/c/563/cho-do-cu"
    }
];

export const MEMBERSHIP = {
    1: {
        name: 'Chim non',
        logo: '/images/icon-membership-03.svg',
        backgroundClass: 'boxMembership--blue',
    },
    2: {
        name: 'Chim xây tổ',
        logo: '/images/icon-membership-04.svg',
        backgroundClass: 'boxMembership--red',
    },
    3: {
        name: 'Chim đầu đàn',
        logo: '/images/icon-membership-05.svg',
        backgroundClass: 'boxMembership--yellow',
    },
};
export const MAP_KEY = "AIzaSyAlogTt0DazaJvfkihRdq0uOtECKlUs3AY";