import { API_URL, API_URL_V2, CHAT } from "./constants";
import {isEmpty, isObject, isArray, has, set, get} from 'lodash';
import {toast} from "react-toastify";
// Example
// const params = {
//     filters: [
//     [{
//         field: 'parent_id',
//         value: 1
//     }, OR{}] AND []
//     ],
//     sorts: [{
//         field: 'created_at',
//         direction: 'DESC',
//     }],
//     size: 10,
//     page: 1,
// };

const axiosRequest = async (url = '/', params = null, config = {}, domain = 'v3') => {
    if (get(config, 'token') == 'default') {
        set(config, ['headers', 'Authorization'], 'Bearer bhzi6vk8uydg09l3iui1zmpu1q13lw54');
    } else if (!isEmpty(get(config, 'token'))) {
        set(config, ['headers', 'Authorization'], config.token);
    }
    delete config.token;
    url += addParams(params);

    let baseUrl = API_URL;
    if ([
        'auth/login',
        'auth/register',
        'auth/login/social/facebook',
        'auth/login/social/google',
        'user',
        'community/home',
        'community/article/filter/index',
        'community/tag?page=1&q=',
        "community/article/term/295?page=1",
        "community/professional/3729/albums?page=1",
        "community/professional/home",
        "user/home/18",
        "user",
        "user/879/threads?page=1",
        "user/879/albums?page=1"
    ].includes(url)) {
        baseUrl = API_URL_V2;
    }
    if (domain === 'v2') {
        baseUrl = API_URL_V2;
    }
    if (domain === 'chat') {
        baseUrl = CHAT;
    }
    if (has(config, 'body')) {
        config.body = (JSON.stringify(config.body));
    }
    if (has(config, 'method')) {
        if (get(config, 'method') != 'GET') {
            set(config, ['headers', 'Content-Type'], 'application/json');
        }
        config.method = config.method;
    }
    if (has(config, 'headers')) {
        config.headers = config.headers;
    }
    let result = null;
    try {
        result = await fetch(baseUrl + url, config);
        if (result.status == 200) {
            const json = await result.json();
            return json;
        }
        const json = await result.text();
        return JSON.parse(json);
    } catch (e) {
        // toast.error('Hành động thất bại');
        // console.log(baseUrl + url, e.message);
    }
    return null;
};

export const addParams = (params) => {
    if (!isObject(params)) {
        return '';
    }
    let query = '';
    let countParam = 0;
    let result = null;
    Object.keys(params).forEach(field => {
        switch (field) {
        case 'filters':
            result = genQueryFilter(params[field], countParam);
            query += result.query;
            countParam += 1;
            break;
        case 'sorts':
            result = genQuerySort(params[field], countParam);
            query += result.query;
            countParam += 1;
            break;
        case 'size':
            query += insertParam('searchCriteria[pageSize]', params[field], countParam);
            countParam += 1;
            break;
        case 'page':
            query += insertParam('searchCriteria[currentPage]', params[field], countParam);
            countParam += 1;
            break;
        default:
            query += insertParam(field, params[field], countParam);
            countParam += 1;
            break;
        }
    });
    return query;
};
const genQuerySort = (params, countParam) => {
    let query = '';
    params.forEach((param, keyParam) => {
        Object.keys(param).forEach((field) => {
            if (['field', 'direction'].includes(field)) {
                let keyQuery = 'searchCriteria[sortOrders][' + keyParam + '][' + field + ']';
                let valueQuery = param[field];
                query += insertParam(keyQuery, valueQuery, countParam);
                countParam++;
            }
        });
    });
    return {query, countParam};
};
const genQueryFilter = (params, countParam) => {
    let query = '';
    params.forEach((group, keyGroup) => {
        if (!isArray(group)) {
            return false;
        }
        group.forEach((filter, keyFilter) => {
            Object.keys(filter).forEach(key => {
                if (['condition_type', 'field', 'value'].includes(key)) {
                    let keyQuery = 'searchCriteria[filter_groups][' + keyGroup + '][filters][' + keyFilter + '][' + key + ']';
                    let valueQuery = filter[key];
                    query += insertParam(keyQuery, valueQuery, countParam);
                    countParam++;
                }
            });
        });
    });
    return {query, countParam};
};
export const insertParam = (key, value, countParam) => {
    const kvp = key + "=" + value;
    let query = '';
    if (countParam == 0) {
        query += '?';
    } else {
        query += '&';
    }
    return query + kvp;
};
export default axiosRequest;
